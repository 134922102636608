import { Component } from 'solid-js'
import styles from '~/components/doc-translations/doc-translations.module.scss'

const DocTranslations: Component = () => {
  return (
    <div class={styles.docTranslations}>
      <p>In order to correctly import raw text translations, they need to use the following format :</p>
      <code class={styles.fileformat}>
{`SERIES_NAME - AUTHOR_NAME - CLIENT_NAME

CHAPTER CHAPTER_INDEX

#Page 1
#1 (Position: 59,19)
Lorem ipsum dolor sit amet
#2 (Position: 43,28)
turpis leo elementum libere
#3 (Position: 20,33)
Vestibulum nec nunc lobortis
#4 (Position: 32,42)
Nunc scelerisque
#5 (Position: 21,47)
Vivamus rhoncus id leo tincidunt ultrices
#6 (Position: 51,49)
consectetur adipiscing elit

#Page 2
#7 (Position: 36,1)
Nunc scelerisque
#8 (Position: 47,3)
mi eget pretium sodales`}
      </code>
      <p><strong>Page:</strong> Used to group translation entries by page, make sure you do not have more pages than what is currently loaded in the Editor.</p>
      <p><strong>Index:</strong> Must be sequential and unique, do not reset on each page.</p>
      <p><strong>Position:</strong> represents the top left coordinate of each translation box in percentage of the width and height of the page. For instance (Position: 59,19) will place the box at 59% of the width of the page, and 19% of the height of the page. It is optional and can be omitted, at the cost of having all the translations on the same place on each page.</p>
    </div>
  )
}

export default DocTranslations
