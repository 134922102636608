import { Title } from '@solidjs/meta'
import DocTranslations from '~/components/doc-translations/doc-translations'
import Standalone from '~/components/standalone/standalone'

export default function TranslationImport() {
  return (
    <Standalone>
      <Title>Translation Import</Title>
      <DocTranslations />
    </Standalone>
  )
}
