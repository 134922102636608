import { Component } from 'solid-js'
import { StandaloneProps } from '~/components/standalone/standalone.interfaces'
import styles from '~/components/standalone/standalone.module.scss'

const Standalone: Component<StandaloneProps> = ({ children }) => {
  return (
    <div class={styles.standalone}>
      {children}
    </div>
  )
}

export default Standalone
